<template>
  <div id="bg">
    <el-row>
      <el-col :span="4" align="right">显示弹出公告</el-col>
      <el-col :span="20">
        <el-switch :active-value=1 :inactive-value=0 v-model="form.is_show"></el-switch>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4"  align="right">弹出公告内容</el-col>
      <el-col :span="20">
        <fuwenben v-model="form.content" :isClear="isClear" :desc="form.content" @itemclick="cpnclick"></fuwenben>
<!--        <el-input type="textarea" v-model="form.content" :isClear="isClear" :desc="form.content" @itemclick="cpnclick"></el-input>-->
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4"  align="right">按钮1显示</el-col>
      <el-col :span="20">
        <el-switch :active-value=1 :inactive-value=0 v-model="form.button[0].is_show"></el-switch>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4"  align="right">按钮1标题</el-col>
      <el-col :span="20">
        <el-input placeholder="请输入按钮1标题" v-model="form.button[0].title"></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4"  align="right">按钮1跳转类型</el-col>
      <el-col :span="20">
        <el-radio @click="form.button[0].url=''" v-model="form.button[0].type" :label=0 >外部链接</el-radio>
        <el-radio @click="form.button[0].url=''" v-model="form.button[0].type" :label=1 >内部跳转</el-radio>
        <el-radio @click="form.button[0].url=''" v-model="form.button[0].type" :label=2 >标签</el-radio>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4"  align="right">按钮1跳转详情</el-col>
      <el-col :span="20">
        <el-select v-model="form.button[0].url2" v-if="form.button[0].type == 1">
          <el-option v-for="item of insiteRoutes" :key="item" :label="item.label" :value="item.value" ></el-option>
        </el-select>
        <el-select v-model="form.button[0].url2" v-if="form.button[0].type == 2">
          <el-option v-for="item of insiteTags" :key="item" :label="item.label" :value="item.value" ></el-option>
        </el-select>
        <el-input class="width_200" v-model="form.button[0].extra" v-if="form.button[0].type > 0" placeholder="请输入额外参数!" ></el-input> <span class="red"  v-if="form.button[0].type > 0">额外参数,可以填写帖子的ID</span>
        <el-input v-model="form.button[0].url" v-if="form.button[0].type == 0" placeholder="请输入跳转详情!" ></el-input>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="4"  align="right">按钮2显示</el-col>
      <el-col :span="20">
        <el-switch :active-value=1 :inactive-value=0 v-model="form.button[1].is_show"></el-switch>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4"  align="right">按钮2标题</el-col>
      <el-col :span="20">
        <el-input placeholder="请输入按钮2标题" v-model="form.button[1].title"></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4"  align="right">按钮2跳转类型</el-col>
      <el-col :span="20">
        <el-radio @click="form.button[1].url=''" v-model="form.button[1].type" :label=0 >外部链接</el-radio>
        <el-radio @click="form.button[1].url=''" v-model="form.button[1].type" :label=1 >内部跳转</el-radio>
        <el-radio @click="form.button[1].url=''" v-model="form.button[1].type" :label=2 >标签</el-radio>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4"  align="right">按钮2跳转详情</el-col>
      <el-col :span="20">
        <el-select v-model="form.button[1].url2" v-if="form.button[1].type == 1">
          <el-option v-for="item of insiteRoutes" :key="item" :label="item.label" :value="item.value" ></el-option>
        </el-select>
        <el-select v-model="form.button[1].url2" v-if="form.button[1].type == 2">
          <el-option v-for="item of insiteTags" :key="item" :label="item.label" :value="item.value" ></el-option>
        </el-select>
        <el-input class="width_200" v-model="form.button[1].extra" v-if="form.button[1].type > 0" placeholder="请输入额外参数!" ></el-input> <span class="red"  v-if="form.button[1].type > 0">额外参数,可以填写帖子的ID</span>
        <el-input v-model="form.button[1].url" v-if="form.button[1].type == 0" placeholder="请输入跳转详情!" ></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8"  align="right"></el-col>
      <el-col :span="16">
        <el-button @click="reset()">重置</el-button>
        <el-button @click="save()">保存</el-button>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import httpClient from "@/config/httpClient";
import fuwenben from "@/components/wangEditor";
// import * as Zlib from "zlib";
// import pako from 'pako'  //引入pako解压与压缩字符串
export default {
  name: "notify",
  components: {
    fuwenben
  },
  data() {
    return {
      isClear: false,
      insiteTags:[
        {label:"社区标签",value:"#tagCommunity"},
        {label:"漫画标签",value:"#tagCartoon"},
        {label:"美图标签",value:"#tagPicture"},
        {label:"小说标签",value:"#tagStory"},
      ],
      insiteRoutes:[
        {label:"关闭窗口",value:"#close"},
        {label:"会员充值",value:"#userPay"},
        {label:"钱包充值",value:"#walletPay"},
        {label:"游戏中心",value:"#gameCenter"},
        {label:"会员分享",value:"#share"},
        {label:"帖子详情",value:"#tagPost"},
        {label:"下载站",value:"#addlogsadver"},
      ],
      form: {
        is_show:0, // 是否显示
        content:"", // 显示内容
        button:[
          { // 多个按钮，增加多个值就可以
            title:"", // 按钮标题
            type:0,// 按钮类型
            url:"", // 按钮跳转地址
            url2:"", // 按钮跳转地址
            extra:"", // 额外参数
            is_show:0,
          },
          { // 多个按钮，增加多个值就可以
            title:"", // 按钮标题
            type:0,// 按钮类型
            url:"", // 按钮跳转地址
            url2:"", // 按钮跳转地址
            extra:"", // 额外参数
            is_show:0,
          },
        ],
      }
    };
  },
  computed: {},
  created() {
    this.getList()
  },
  mounted() {

  },
  methods: {
    cpnclick(item) {
      this.form.content = item;
    },
    getList(){
      httpClient("systemSetting").post().then((res)=>{
        if (res.code == 0){
         //  var data = JSON.parse(decodeURIComponent(res.data.pop_notice))
          var data = JSON.parse(res.data.pop_notice)
          data.content = this.$common.htmlDecode(decodeURIComponent(data.content))
          if (data.button[1].type > 0){
            // 参数解析
            var index = data.button[1].url.indexOf("/")
            if (index > 0){
              data.button[1].url2 = data.button[1].url.substr(0,index)
              data.button[1].extra = data.button[1].url.substr(index+1)
            }else{
              data.button[1].url2 = data.button[1].url
              data.button[1].extra = ""
            }
          }
          if (data.button[0].type > 0){
            // 参数解析
            index = data.button[0].url.indexOf("/")
            if (index > 0){
              data.button[0].url2 = data.button[0].url.substr(0,index)
              data.button[0].extra = data.button[0].url.substr(index+1)
            }else{
              data.button[0].url2 = data.button[0].url
              data.button[0].extra = ""
            }
          }
          this.form = data
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    save(){
      if (this.form.button[0].type > 0){
        if (this.form.button[0].url2 == undefined || this.form.button[0].url2 == ""){
          this.$message({message:"请选择按钮2的下拉框",type:"error"})
          return
        }
        this.form.button[0].url = this.form.button[0].url2 + (this.form.button[0].extra?"/"+this.form.button[0].extra:"")
      }
      if (this.form.button[1].type > 0){
        if (this.form.button[1].url2 == undefined || this.form.button[1].url2 == ""){
          this.$message({message:"请选择按钮2的下拉框",type:"error"})
          return
        }
        this.form.button[1].url = this.form.button[1].url2 + (this.form.button[1].extra?"/"+this.form.button[1].extra:"")
      }
      console.log(this.form)
      httpClient("noticeSetting").post({
        is_show:this.form.is_show,
        // content:this.$common.htmlDecode(this.form.content),
        content:this.$common.htmlEncode(this.form.content),
        b1_title:this.form.button[0].title,
        b1_type:this.form.button[0].type,
        b1_url:this.form.button[0].url,
        b1_show:this.form.button[0].is_show,
        b2_title:this.form.button[1].title,
        b2_type:this.form.button[1].type,
        b2_url:this.form.button[1].url,
        b2_show:this.form.button[1].is_show,
      }).then((res)=>{
        if (res.code == 0){
          this.$message({message:"修改成功",type:"success"})
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    reset(){
      this.getList()
    }
  },
};
</script>
<style>
.el-row{
  margin-bottom: 5px;
}
.el-row .el-col{
  padding-right: 5px;
}
</style>